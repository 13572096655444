.#{$rt-namespace}__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: 0.3s ease;

  align-items: center;
  justify-content: center;
  display: flex;

  &--light {
    color: #000;
  }

  & > svg {
    fill: #666666;
    height: 16px;
    width: 14px;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}
