$datepicker__interaction-color: #1188ee !default;
$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $datepicker__interaction-color !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: $datepicker__selected-color !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__cell-height: 28px !default;
$datepicler__cell-padding: 4px !default;

$datepicker__border-radius: ($datepicker__cell-height / 2) !default;
$datepicker__day-margin: 0.166rem !default;

$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: 'Roboto', helvetica, arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;
