.#{$rt-namespace}__toast {
  font-size: 16px;
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 16px;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.2), inset 4px 0 0 #33a300;
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  &--rtl {
    direction: rtl;
  }
  &-body {
    flex: 1 1 auto;
    margin-right: 16px;
    display: flex;
    align-items: center;
    color: #333333;
    & > div:last-child {
      flex: 1;
    }
  }
  &-icon {
    margin-inline-end: 10px;
    width: 20px;
    flex-shrink: 0;
    display: flex;
  }
}

.#{$rt-namespace}--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.#{$rt-namespace}--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}

.f {
  --y: calc(var(--len) - var(--nth));
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translate3d(0, calc(var(--y) * -40%), 0)
    scale(calc(1 - 0.05 * var(--y)));
  transition: all 0.3s;
  min-height: 80px;
}
