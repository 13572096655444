@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0;
  background: #f5f5f5;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
}
a,
p {
  color: #333;
}
p {
  color: #333;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes remove {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #fff3f3;
    max-height: 75%;
  }
  50% {
    background-color: #ffc3bc;
    max-height: 50%;
  }
  75% {
    background-color: #fff3f3;
    opacity: 0.5;
    max-height: 25%;
  }
  100% {
    background-color: #fff;
    opacity: 0;
    max-height: 0;
  }
}

@keyframes update {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }

  45% {
    transform: scale(1.5) translateY(2px);
  }

  55% {
    transform: scale(1.5) translateY(2px);
  }

  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

@keyframes increase {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-16px);
  }
}

@keyframes decrease {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(16px);
  }
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  text-align: inherit;
  -webkit-appearance: none;
  outline: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:focus:not(:focus-visible) {
  outline: none;
}

button:focus:not(:-moz-focusring) {
  outline: none;
}
